import LoadingIcon from '../assets/loading.svg';
import styles from './Loading.module.scss';

const DEFAULT_LOADING_ICON_SIZE = 100;

const Loading = ({ size }: { size?: number }) => (
  <div className={styles.loadingWrapper}>
    <img
      src={LoadingIcon}
      alt="Loading"
      width={size || DEFAULT_LOADING_ICON_SIZE}
      height={size || DEFAULT_LOADING_ICON_SIZE}
    />
  </div>
);

export default Loading;
