import { useI18next } from 'gatsby-plugin-react-i18next';
import ContentBNPLCompare from '../../Modals/ContentBNPLCompare';
import SideModal from '../../SideModal/SideModal';
import styles from './ModalBNPL.module.scss';

const ModalBNPL = ({
  active,
  close,
}: {
  active: boolean;
  close: () => void;
}) => {
  const { t } = useI18next();

  return (
    <SideModal
      title={t('compare-title')}
      active={active}
      close={close}
      containerClassName={styles.bigTableContainer}
    >
      <ContentBNPLCompare />
    </SideModal>
  );
};

export default ModalBNPL;
