import cx from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import {
  forwardRef,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import useImageQuery from '../../../utils/use-image-query';
import useStickyProgress from '../../../utils/use-sticky-progress';
import Swiper from '../../Swiper/Swiper';
import styles from './BetterWays.module.scss';

type Item = {
  title: ReactNode | string;
  description: ReactNode | string;
  screen: any;
};

const Indicator = ({
  className,
  activeIndex,
}: {
  className?: string;
  activeIndex: number;
}) => {
  return (
    <div className={cx(styles.slideIndicator, className)}>
      <div
        className={cx(
          styles.indexItem,
          styles.dot1,
          activeIndex === 0 ? styles.active : styles.inactive
        )}
      >
        1
      </div>
      <div
        className={cx(
          styles.indexItem,
          styles.dot2,
          activeIndex === 1 ? styles.active : styles.inactive
        )}
      >
        2
      </div>
      <div
        className={cx(
          styles.indexItem,
          styles.dot3,
          activeIndex === 2 ? styles.active : styles.inactive
        )}
      >
        3
      </div>
      <div
        className={cx(
          styles.indexItem,
          styles.dot4,
          activeIndex === 3 ? styles.active : styles.inactive
        )}
      >
        4
      </div>
    </div>
  );
};

const BetterWay = forwardRef<
  HTMLDivElement,
  {
    item: Item;
    index: number;
    active?: boolean;
  }
>(({ item, index, active }, ref): JSX.Element => {
  return (
    <div
      ref={ref}
      className={cx(
        styles.slideContainer,
        styles[`slide${index}`],
        active && styles.active
      )}
    >
      <div className={styles.slide}>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <h4>{item.title}</h4>
            <p>{item.description}</p>
          </div>
        </div>
        <div className={styles.figure} role="presentation">
          <GatsbyImage
            image={item.screen.childImageSharp.gatsbyImageData}
            alt=""
            className={styles.screen}
          />
        </div>
      </div>
    </div>
  );
});

const BetterWays = ({ className }: { className?: string }) => {
  const { i18n, t } = useI18next();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentMobileSlide, setCurrentMobileSlide] = useState(0);

  const slide1Ref = useRef<HTMLDivElement>(null);
  const slide2Ref = useRef<HTMLDivElement>(null);
  const slide3Ref = useRef<HTMLDivElement>(null);
  const slide4Ref = useRef<HTMLDivElement>(null);

  const {
    srcBetterWay1ScreenEn,
    srcBetterWay1ScreenJa,
    srcBetterWay2ScreenEn,
    srcBetterWay2ScreenJa,
    srcBetterWay3ScreenEn,
    srcBetterWay3ScreenJa,
    srcBetterWay4ScreenEn,
    srcBetterWay4ScreenJa,
  } = useImageQuery();

  const betterWaysData = useMemo(() => {
    return [
      {
        title: (
          <Trans
            i18nKey="consumer-2-better-way-1-title"
            components={{ br: <br /> }}
          />
        ),
        description: (
          <Trans
            i18nKey="consumer-2-better-way-1-desc"
            components={{ br: <br /> }}
          />
        ),
        screen:
          i18n.language === 'en'
            ? srcBetterWay1ScreenEn
            : srcBetterWay1ScreenJa,
      },
      {
        title: (
          <Trans
            i18nKey="consumer-2-better-way-2-title"
            components={{ br: <br /> }}
          />
        ),
        description: (
          <Trans
            i18nKey="consumer-2-better-way-2-desc"
            components={{ br: <br /> }}
          />
        ),
        screen:
          i18n.language === 'en'
            ? srcBetterWay2ScreenEn
            : srcBetterWay2ScreenJa,
      },
      {
        title: (
          <Trans
            i18nKey="consumer-2-better-way-3-title"
            components={{ br: <br /> }}
          />
        ),
        description: (
          <Trans
            i18nKey="consumer-2-better-way-3-desc"
            components={{ br: <br /> }}
          />
        ),
        screen:
          i18n.language === 'en'
            ? srcBetterWay3ScreenEn
            : srcBetterWay3ScreenJa,
      },
      {
        title: (
          <Trans
            i18nKey="consumer-2-better-way-4-title"
            components={{ br: <br /> }}
          />
        ),
        description: (
          <Trans
            i18nKey="consumer-2-better-way-4-desc"
            components={{ br: <br /> }}
          />
        ),
        screen:
          i18n.language === 'en'
            ? srcBetterWay4ScreenEn
            : srcBetterWay4ScreenJa,
      },
    ];
  }, [i18n.language]);

  const slidesForParallax = useMemo(() => {
    return (
      <>
        <BetterWay item={betterWaysData[0]} index={0} ref={slide1Ref} />
        <BetterWay item={betterWaysData[1]} index={1} ref={slide2Ref} />
        <BetterWay item={betterWaysData[2]} index={2} ref={slide3Ref} />
        <BetterWay item={betterWaysData[3]} index={3} ref={slide4Ref} />
      </>
    );
  }, [betterWaysData]);

  const slides = useMemo(() => {
    return (
      <>
        <BetterWay item={betterWaysData[0]} index={0} />
        <BetterWay item={betterWaysData[1]} index={1} />
        <BetterWay item={betterWaysData[2]} index={2} />
        <BetterWay item={betterWaysData[3]} index={3} />
      </>
    );
  }, [betterWaysData]);

  const setMobileActiveSlide = useCallback((index: number) => {
    setCurrentMobileSlide(index);
  }, []);

  const setActiveSlide = useCallback((index: number) => {
    const slides = [slide1Ref, slide2Ref, slide3Ref, slide4Ref];

    setCurrentSlide(index);

    for (let i = 0; i < slides.length; i += 1) {
      const delta = i - index;
      const currentRef = slides[i];

      if (delta <= -1) {
        // prev
        currentRef.current?.classList.remove(styles.active);
        currentRef.current?.classList.remove(styles.next);
        currentRef.current?.classList.add(styles.prev);
      } else if (delta === 0) {
        currentRef.current?.classList.add(styles.active);
        currentRef.current?.classList.remove(styles.next);
        currentRef.current?.classList.remove(styles.prev);
      } else {
        // next
        currentRef.current?.classList.remove(styles.active);
        currentRef.current?.classList.add(styles.next);
        currentRef.current?.classList.remove(styles.prev);
      }
    }
  }, []);

  const { ref } = useStickyProgress({
    effect: ({ progressBetween }) => {
      if (progressBetween < 0.2) {
        setActiveSlide(0);
      } else if (progressBetween >= 0.2 && progressBetween < 0.4) {
        setActiveSlide(1);
      } else if (progressBetween >= 0.4 && progressBetween < 0.6) {
        setActiveSlide(2);
      } else if (progressBetween >= 0.6) {
        setActiveSlide(3);
      }
    },
  });

  return (
    <div ref={ref} className={cx(styles.parallaxContainer)}>
      <div className={styles.contentContainer}>
        <section
          id="better-ways"
          className={cx(className, styles.betterWaysContainer)}
        >
          <div className={styles.betterWays}>
            <div className={styles.box}>
              <div className={styles.header}>
                <div className={styles.titles}>
                  <b>
                    <Trans
                      i18nKey="consumer-2-better-way-subtitle"
                      components={{ br: <br /> }}
                    />
                  </b>
                  <h2>
                    <Trans
                      i18nKey="consumer-2-better-way-title"
                      components={{ br: <br /> }}
                    />
                  </h2>
                </div>
              </div>
              <Swiper
                title={null}
                className={cx(styles.swiperContainer)}
                callback={setMobileActiveSlide}
              >
                {slides}
              </Swiper>
              <div className={cx(styles.slidesContainer)}>
                <div className={styles.slides}>{slidesForParallax}</div>
              </div>

              <Indicator
                className={styles.desktop}
                activeIndex={currentSlide}
              />

              <Indicator
                className={styles.mobile}
                activeIndex={currentMobileSlide}
              />
            </div>
          </div>
        </section>
        <div className={styles.actionsContainer}>
          <div className={styles.box}>
            <a
              href="#consumer-mobile-app-intro"
              className={styles.secondaryAction}
            >
              {t('consumer-2-download-app-label')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BetterWays;
