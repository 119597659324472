import cx from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { ReactNode } from 'react';
import useImageQuery from '../../../utils/use-image-query';
import Button from '../../Form/Button';
import Swiper from '../../Swiper/Swiper';
import styles from './Why.module.scss';

type Item = {
  first?: boolean;
  title: ReactNode | string;
  description: ReactNode | string;
  figure: any;
};

const WhyItem = ({
  item,
  showModal,
  className,
}: {
  item: Item;
  showModal: () => void;
  className?: string;
}) => {
  const { t } = useI18next();

  return (
    <div className={cx(styles.slideContainer, className)}>
      <div className={styles.slide}>
        <div className={styles.figure} role="presentation">
          <figure>
            <GatsbyImage
              image={item.figure.childImageSharp.gatsbyImageData}
              alt=""
              className={styles.figureImg}
            />
          </figure>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            {item.first && (
              <div className={styles.firstTag}>{t('first-in-japan')}</div>
            )}
            <h4>{item.title}</h4>
            <p>{item.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Why = ({
  className,
  showModal,
}: {
  className?: string;
  showModal: () => void;
}) => {
  const { t, i18n } = useI18next();
  const {
    srcConsumerItem1Figure,
    srcConsumerItem2FigureEn,
    srcConsumerItem2FigureJa,
    srcConsumerItem3Figure,
    srcConsumerItem4Figure,
  } = useImageQuery();

  const ITEM_DATA = [
    {
      first: true,
      title: (
        <Trans i18nKey="consumer-2-why-1-title" components={{ br: <br /> }} />
      ),
      description: (
        <Trans i18nKey="consumer-2-why-1-desc" components={{ br: <br /> }} />
      ),
      figure: srcConsumerItem1Figure,
    },
    {
      title: (
        <Trans i18nKey="consumer-2-why-2-title" components={{ br: <br /> }} />
      ),
      description: (
        <Trans i18nKey="consumer-2-why-2-desc" components={{ br: <br /> }} />
      ),
      figure:
        i18n.language === 'en'
          ? srcConsumerItem2FigureEn
          : srcConsumerItem2FigureJa,
    },
    {
      title: (
        <Trans i18nKey="consumer-2-why-3-title" components={{ br: <br /> }} />
      ),
      description: (
        <Trans i18nKey="consumer-2-why-3-desc" components={{ br: <br /> }} />
      ),
      figure: srcConsumerItem3Figure,
    },
    {
      title: (
        <Trans i18nKey="consumer-2-why-4-title" components={{ br: <br /> }} />
      ),
      description: (
        <Trans i18nKey="consumer-2-why-4-desc" components={{ br: <br /> }} />
      ),
      figure: srcConsumerItem4Figure,
    },
  ];

  return (
    <Swiper
      id="why"
      className={cx(styles.container, className)}
      activeSlideClassName={styles.active}
      indicatorClassName={styles.indicator}
      title={t('consumer-2-why-title')}
      footer={
        <div className={styles.actions}>
          <div className={styles.actionsBox}>
            <Button label={t('consumer-2-compares')} onClick={showModal} />
          </div>
        </div>
      }
    >
      {ITEM_DATA.map((item, index, arr) => (
        <WhyItem
          key={`why-${index}`}
          item={item}
          showModal={showModal}
          className={cx(index === 0 && styles.active)}
        />
      ))}
    </Swiper>
  );
};

export default Why;
